 <template>
    <div class="page">

        <!-- 搜索条件表单 -->
        <el-form class="ser_form" size="small"  label-width="110px">
            <el-form-item class="el_form_item" label="注册人手机">
                <el-input class="el_input" v-model="form.tel" clearable></el-input>
            </el-form-item>
            <el-form-item class="el_form_item" label="注册身份证">
                <el-input class="el_input" v-model="form.id_card_num" clearable></el-input>
            </el-form-item>
            <el-form-item class="el_form_item" label="公司名称">
                <el-input class="el_input" v-model="form.name" clearable></el-input>
            </el-form-item>
            <el-form-item class="el_form_item" label="营业执照号码">
                <el-input class="el_input" v-model="form.license_code" clearable></el-input>
            </el-form-item>
            <el-form-item class="el_form_item" label="审核状态">
                <el-select class="el_input" v-model="form.check_status" clearable>
                    <el-option label="不限" value=""></el-option>
                    <el-option label="待审核" value="1"></el-option>
                    <el-option label="已通过" value="2"></el-option>
                    <el-option label="未通过" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label-width="0">
                <el-button type="primary" @click="ser">查询</el-button>
                <!-- <el-button type="success" @click="open_add_view">添加</el-button> -->
            </el-form-item>
        </el-form>

        <!-- 表格 -->
        <div class="tab_height">
            <el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
                <el-table-column label="时间相关" width="190">
                    <template slot-scope="scope">
                        <div>提交:{{scope.row.creat_time_text}}</div>
                        <div>通过:{{scope.row.pass_time_text}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="用户姓名/电话">
                    <template slot-scope="scope">
                        <div>{{scope.row.user_name}}</div>
                        <div>{{scope.row.user_tel}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="名称/法人/信用代码">
                    <template slot-scope="scope">
                        <div>{{scope.row.name}}</div>
                        <div>{{scope.row.legal_person}}</div>
                        <div>{{scope.row.license_code}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="对公银行/卡号">
                    <template slot-scope="scope">
                        <div>{{scope.row.bank_name}}</div>
                        <div>{{scope.row.bank_card}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="公司地址/电话">
                    <template slot-scope="scope">
                        <div>{{scope.row.addr}}</div>
                        <div>{{scope.row.tel}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="check_status_text"></el-table-column>
                
                <!-- 操作行 -->
                <el-table-column fixed="right" label="操作" width="235">
                    <template slot-scope="scope">
                        
                        <!-- 驳回/通过 -->
                        <el-button v-if="scope.row.check_status==1" @click="edit_sub({id:scope.row.id,check_status:2})" size="mini" type="success">通过</el-button>
                        <el-button v-if="scope.row.check_status==1" @click="edit_sub({id:scope.row.id,check_status:3})" size="mini" type="danger">驳回</el-button>
                        <el-button v-if="scope.row.check_status==2" @click="edit_sub({id:scope.row.id,check_status:3})" size="mini" type="danger">驳回</el-button>
                        <el-button v-if="scope.row.check_status==3" @click="edit_sub({id:scope.row.id,check_status:2})" size="mini" type="success">通过</el-button>
                        
                        <!-- 其他 -->
                        <el-button @click="open_imgs_view(scope.row)" size="mini" type="primary">营业执照</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页 -->
        <el-pagination 
            class="margin-top-xl" 
            @size-change="page_size_change"
            @current-change="page_current_change" 
            :current-page="page.p" 
            :page-sizes="[10, 20, 30, 40]"
            :page-size="page.num" 
            :total="list.total*1"
            layout="total, sizes,prev, pager, next, jumper" 
        >
        </el-pagination>

        <!-- 设置服务费率弹窗 -->
        <el-dialog top="3vh" title="设置服务费率" width="500px" :visible.sync="sc_rate.is_show">
            <el-form status-icon label-position="left" label-width="70px">
                <el-form-item label="服务费率" >
                    <el-input class="el_input" type="number" v-model="sc_rate.data.rate" ></el-input>
                </el-form-item>
                <el-form-item label="计算方式" >
                    <el-select class="el_input" v-model="sc_rate.data.type" clearable>
                        <el-option label="除法" value="1"></el-option>
                        <el-option label="乘法" value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div style="text-align:center">
                <el-button type="primary" @click="sc_rate_sub">提交</el-button>
            </div>
        </el-dialog>

        <!-- 营业执照图片弹窗 -->
        <el-dialog top="3vh" title="营业执照图片" width="50%" :visible.sync="license_img.is_show">
            <img :src="license_img.src" style="width:100%">
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {

                //bucket
                bucket:{},
                
                //搜索条件
                form: {
                    tel:'',//电话
                    id_card_num:'',//身份证号
                    name:'',//公司名称
                    license_code:'',//是否可用
                    check_status:'',//身份证号
                },

                //表格数据
                list: {
                    loading: false,//loading特效
                    data: [],//列表数据
                    total: 0,//列表总记录数
                },

                //页码信息
                page: {
                    need_num:1,
                    num: 10,
                    p: 1,
                },

                //服务费率修改弹窗
                sc_rate:{
                    data:{
                        rate:0,//服务费率
                        type:'',//计算方式
                        id:'',
                    },
                    is_show:false,
                },

                //营业执照弹窗
                license_img:{
                    src:'',
                    is_show:false,
                },
            }
        },
        created() {

            //取出bucket
            this.bucket=this.$my.cache.get('bucket')
            
            //读取页面数据
            this.get_page_data()
        },
        methods: {

            //打开营业执照图片
            open_imgs_view(item){
                
                //打开弹窗
                this.license_img.is_show=true;

                //设置图片地址
                this.license_img.src=this.$my.qiniu.make_src('license',item.license_img)
            },

            //提交修改服务费率设置
            sc_rate_sub(){

                //提交
                this.edit_sub({
                    id:this.sc_rate.data.id,
                    service_charge_rate:this.sc_rate.data.rate,
                    service_charge_type:this.sc_rate.data.type,
                });

                //关闭弹窗
                this.sc_rate.is_show=false;
            },

            //打开服务费率设置弹窗
            open_sc_rate_view(item){

                //置入数据
                this.sc_rate.data={
                    rate:item.service_charge_rate,
                    type:item.service_charge_type,
                    id:item.id
                }

                //打开弹出层
                this.sc_rate.is_show=true;
            },

            //提交修改
            edit_sub(obj){

                //提交
                this.$my.net.req({
                    data:{
                        mod:'company',
                        ctr:'company_edit_admin',
                        ...obj
                    },
                    callback:(data)=>{
                        this.$my.other.msg({
                            type:'success',
                            str:'操作成功'
                        });
                        this.get_page_data()
                    },
                });
            },

            //前往添加页面
            open_add_view(){
                this.get_menu_list()
                this.add_para.is_show=true
            },

            //前往修改页面
            open_edit_view(item){
                this.get_menu_list()
                this.edit_para.user_info=item
                this.edit_para.is_show=true
            },

            //搜索
            ser(){
                this.get_page_data()
            },

            //页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

            //读取页面数据
            get_page_data(){

                //加载中...
                if(this.list.loading){
                    return;
                }

                //置为加载中
                this.list.loading=true;

                //读取数据
                this.$my.net.req({
                    data:{
                        mod:'company',
                        ctr:'company_list_admin',
                        ...this.form,
                        ...this.page
                    },
                    callback:(data)=>{
                        
                        //加载完毕
                        this.list.loading=false

                        //总数
                        this.list.total=data.max

                        //预处理
                        for(let item of data.list){

                            //时间
                            item.creat_time_text=this.$my.other.totime(item.creat_time);
                            item.pass_time_text=item.pass_time>0?this.$my.other.totime(item.pass_time):"尚未通过";

                            //实名状态
                            switch(item.check_status){
                                case '1':item.check_status_text='审核中';break;
                                case '2':item.check_status_text='审核通过';break;
                                case '3':item.check_status_text='审核驳回';break;
                            }

                            //服务费计算方式
                            switch(item.service_charge_type){
                                case '1':item.service_charge_type_text='除法模式';break;
                                case '2':item.service_charge_type_text='乘法模式';break;
                            }
                        }

                        //渲染
                        this.list.data=data.list
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>

    //弹出层
    .in_tab_img_item{
        width: 300px;
    }

    //用户修改/添加表单的额外权限菜单部分
    .menu_list{
        background-color:#eee;
        height: 500px;
        overflow: auto;
        padding: 0 20px;
        margin-top: 10px;
        .children_list{
            margin-left: 20px;
        }
    }

    //其他
    .page{
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        height: calc(100% - 40px);
        overflow: auto;
    }
    .ser_form{
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    .tab_height{
        height:calc(100% - 100px);
    }
    .el_form_item{
        width: 275px;
    }
    .el_input{
        width:94%
    }
    .btn_left{
        margin: 0;
    }
</style>